

















































import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import { TrooperzMetadata, MutableAttributes, TrooperzBoxParams, MissionMode, ArtBoxParams, BalanceTzkt } from '@/type';
import { getTokenBalanceOfUser } from '@/apiCaller/tzktApi';    
import { getIpfsPath, getReqForLvl, getLvlWithXp } from '../../utils/utilities'
import ArtBox from '../ArtBox.vue';
import BuyBtn from '../BuyBtn.vue';

@Component({
    components: {
        ArtBox,
        BuyBtn
    }
})
export default class Artwork extends Vue {
    artworkList: BalanceTzkt[] = []
    loadingArtwork = false
    currentAddress: string | null = null
    fullscreen = false
    currentFullscreenImg: string | null = null

    async mounted () {
        this.loadingArtwork = true
        await this.getAddress()
        await this.getArtwork()
        this.loadingArtwork = false
    }

    getIpfsPath (path: string) {
        return getIpfsPath(path)
    }

    async getAddress () {
        const activeAccount = await this.$wallet.client.getActiveAccount()
        if (activeAccount?.address) {
            const address = activeAccount.address
            this.currentAddress = address
        } else {
            this.currentAddress = null
        }
    }

    async getArtwork () {
        if (this.$config && this.currentAddress) {
            const result = await getTokenBalanceOfUser(this.currentAddress, 
                                                                this.$config?.networkTzkt,   
                                                                this.$config?.contractArtwork)
            this.artworkList = result.data
        }
    }

    setCurrentFullscreen (item: BalanceTzkt) {
        if (item && item.token && item.token.metadata && item.token.metadata.artifactUri) {
            this.currentFullscreenImg = item.token.metadata.artifactUri
            this.fullscreen = true
        }
    }

    get buyArtworksPath () {
        if (this.$config) {
            return { name: 'Marketplace' , query: {contractSearch: this.$config.contractArtwork}}
        }
        return { name: 'Marketplace'}
    }

}
